import Vue from 'vue'
import VueRouter from 'vue-router'
import { getToken } from '@/utils/auth' //

Vue.use(VueRouter)
const routes = [
  {
    path: '/region',
    name: 'region',
    component: () => import('../views/region/index.vue'),
    ext: {
      showHeader: true,
      showTabbar: false,
      isBg: 2,
    }
  },
  {
    path: '/domain',
    name: 'domain',
    component: () => import('../views/domain/index.vue'),
    ext: {
      showHeader: true,
      showTabbar: false,
      isBg: 2,
    }
  },
  {
    path: '/store',
    name: 'store',
    component: () => import('../views/store/index.vue'),
    ext: {
      showHeader: true,
      showTabbar: false,
      isBg: 2,
    }
  },
  {
    path: '/rank',
    name: 'rank',
    component: () => import('../views/rank/index.vue'),
    ext: {
      showHeader: true,
      showTabbar: false,
      isBg: 2,
    }
  },
  {
    path: '/address_book',
    name: 'address_book',
    component: () => import('../views/address_book/index.vue'),
    ext: {
      showHeader: true,
      showTabbar: false,
      isBg: 2,
    }
  },
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home/index.vue'),
    ext: {
      showHeader: true,
      showTabbar: false,
      isBg: 2,
    }
  },
  {
    path: '/details',
    name: 'details',
    component: () => import('../views/details/index.vue'),
    ext: {
      showHeader: true,
      showTabbar: false,
      isBg: 2,
    }
  },
  {
    path: '/newly_added',
    name: 'newly_added',
    component: () => import('../views/newly_added/index.vue'),
    ext: {
      showHeader: true,
      showTabbar: false,
      isBg: 2,
    }
  },
  // {
  //   path: '/',
  //   name: 'home',
  //   component: () => import('../views/HomeTest.vue'),
  //   ext: {
  //     title: 'page.home.title',
  //     navTitle: 'page.home.navTitle',
  //     showHeader: true,
  //     showTabbar: true,
  //     isTabbar: true,
  //     tabbarIcon: "home"
  //   }
  // },
  {
  path: '/login',
    name: 'login',
    component: () => import('../views/login/index.vue'),
    ext: {
  title: 'page.home.title',
      navTitle: 'page.home.navTitle',
      showHeader: false,
      showTabbar: true,
      isTabbar: true,
      isBg: 3,
      tabbarIcon: "home"}
},
  {
    path: '/architecture',
    name: 'architecture',
    component: () => import('../views/architecture/index.vue'),
    ext: {
      showHeader: true,
      showTabbar: false,
      isBg: 2,
    }
  },
  {
    path: '/architecture_confirm',
    name: 'architecture_confirm',
    component: () => import('../views/architecture_confirm/index.vue'),
    ext: {
      showHeader: true,
      showTabbar: false,
      isBg: 2,
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = router
  router.matcher = newRouter.matcher // reset router
}

// 路由拦截操作
router.beforeEach((to, from, next) => {

  const hasToken = getToken()

  if (hasToken) {
      next()
  }else{
    console.log(to.path)
    if (to.path == '/login') {
      next()
    }else{
      next({ path: '/login' })
    }


  }
})


export default router