const getters = {
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  zone_ids: state => state.user.zone_ids,
  work_status: state => state.user.work_status,
  job_status: state => state.user.job_status,
  area_ids: state => state.user.area_ids,
  company_ids: state => state.user.company_ids,
  userinfo: state => state.user.userinfo,
  roles: state => state.user.roles,
  permission_routes: state => state.permission.routes,
  name: state => state.user.name,
  errorLogs: state => state.errorLog.logs
}
export default getters
