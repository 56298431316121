import Vue from 'vue'
import Mint from 'mint-ui'
import Vant from 'vant';
import 'vant/lib/index.css';
import './base.css'
import 'mint-ui/lib/style.css'
import App from './App.vue'
import router from './router'
import store from './store'
import {
  i18n
} from './utils/i18n'
import VueCountryCode from "vue-country-code-select"
import VueClipboard from "vue-clipboard2"
Vue.config.productionTip = false

//TODO:Init mint
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/zh-CN' // lang i18n
Vue.use(ElementUI, { locale })
Vue.use(Mint)
Vue.use(Vant)
Vue.use(VueCountryCode);
Vue.use(VueClipboard)

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')