import Vue from 'vue'
import VueI18n from 'vue-i18n'

//TODO:Init global I18n
Vue.use(VueI18n)

const defaultLang = "en-US";
const i18n = new VueI18n({
    locale: (() => {
        if (localStorage.getItem('lang')) {
            return localStorage.getItem('lang')
        }
        return defaultLang;
    })(),
    messages: {
        'en-US': require('../assets/language/en-US'),
        'zh-SG': require('../assets/language/zh-SG')
    }
})
const languageList = {
    "English (United States)": 'en-US',
    '中文（新加坡）': 'zh-SG',
}

function setLanguage(lang) {
    localStorage.setItem('lang', lang)
    i18n.locale = lang
}


export { i18n, languageList, setLanguage }

