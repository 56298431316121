<template>
  <div id="app">
    <router-view
      style="
        min-height: calc(100vh - 40px);
        max-width: 100%;
        overflow: hidden;
      "
    ></router-view>
  </div>
</template>

<script>
import "@/assets/style/common.css";
export default {
  watch: {
    $route() {
      window.scrollTo(0, 0);
      // let _r = this.$router.options.routes.find((r) => {
      //   return r.path == this.$route.path;
      // });
      // if (_r) {
      //   document.title = this.$t(_r.ext.title);
      // }
    },
  },
  computed: {},
  methods: {},
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
  background: #FFFFFF;
}
</style>
